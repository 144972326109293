import React, { useState, useEffect } from "react";
import {
	Residence,
	ResidenceGroup,
	ResidenceScheme,
	ResidenceGroupScheme,
	ErrorScheme,
} from "client-v2";
import { Constant } from "client-v2";
import { Copy } from "lucide-react";
import {
	Input,
	Modal,
	Dropdown,
	Form,
	Text,
	Label,
	Button,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import { styled } from "styled-components";

const OutdatedAddressContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const ResidenceInfoModal = (props: Props) => {
	const phoneUtil =
		require("google-libphonenumber").PhoneNumberUtil.getInstance();

	const [residenceGroupOptions, setResidenceGroupOptions] = useState<
		{ id: string; title: string }[]
	>([]);
	const [timer, setTimer] = useState<any>(undefined);
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState<string | undefined>(undefined);

	const [form, setForm] = useState<any>({
		address: props.residence.address ?? "",
		sortAddress: props.residence.shortAddress ?? "",
		city: props.residence.city ?? "",
		zipCode: props.residence.zipCode ?? "",
		country: props.residence.country ?? "",
		province: props.residence.province ?? "",
		route: props.residence.route ?? "",
		routeNumber: props.residence.routeNumber ?? "",
		routeInfo: props.residence.routeInfo ?? "",
		location: {
			lat: props.residence.location.latitude ?? 0,
			lng: props.residence.location.longitude ?? 0,
		},
		timeZone: props.residence.timeZone ?? "Europe/Madrid",
	});

	const [phone, setPhone] = useState<{
		value: string | undefined;
		countryCode: string | undefined;
	}>({
		value: undefined,
		countryCode: undefined,
	});
	const [residenceGroupId, setResidenceGroupId] = useState<
		{ id: string; title: string } | undefined
	>(
		props.residenceGroup
			? {
					id: props.residenceGroup.objectId,
					title: props.residenceGroup.name,
			  }
			: undefined
	);
	const [error, setError] = useState<string>("");

	useEffect(() => {
		if (props.residence.phone) {
			const result = phoneUtil.parseAndKeepRawInput(
				props.residence.phone
			);
			const values = result.values_;
			setPhone({
				value: values[2].toString(),
				countryCode: "+" + values[1],
			});
		} else {
			setPhone({
				value: undefined,
				countryCode: "+34",
			});
		}
	}, []);

	const data = {
		address: form.data?.address ?? props.residence.address,
		shortAddress: form.data?.shortAddress ?? props.residence.shortAddress,
		location: {
			latitude:
				form.data?.location?.lat ?? props.residence.location.latitude,
			longitude:
				form.data?.location?.lng ?? props.residence.location.longitude,
		},
		province: form.data?.province ?? props.residence.province,
		route: form.data?.route ?? props.residence.route,
		routeNumber: form.data?.routeNumber ?? props.residence.routeNumber,
		routeInfo: form.data?.routeInfo ?? props.residence.routeInfo,
		country: form.data?.country ?? props.residence.country,
		city: form.data?.city ?? props.residence.city,
		zipCode: form.data?.zipCode ?? props.residence.zipCode,
		timeZone: form.data?.timeZone ?? props.residence.timeZone,
	};

	const onSave = async () => {
		setLoading(true);
		Residence.set(props.residence.objectId, {
			name: name,
			...data,
			phone:
				phone.countryCode && phone.value
					? phone.countryCode + phone.value
					: undefined,
			residenceGroupId: residenceGroupId?.id,
		})
			.then((residence) => {
				console.log(residence);
				setLoading(false);
				props.onSave(residence.data);
			})
			.catch((error: ErrorScheme) => {
				setError(error.message);
				setLoading(false);
			});
	};

	const onLocationChangeForm = (result: any) => {
		setForm(result);
	};

	const onNameChange = (e: any) => {
		setName(e.target.value);
	};

	const onPhoneChange = (e: any) => {
		if (e.isValid) {
			setPhone({
				countryCode: e.country,
				value: e.value,
			});
		}
	};

	const onSearch = (text: string) => {
		if (timer) {
			clearTimeout(timer);
			setTimer(undefined);
		}
		setTimer(
			setTimeout(() => {
				searchResidenceGroup(text);
			}, 500)
		);
	};

	const searchResidenceGroup = async (text: string) => {
		setLoading(true);
		ResidenceGroup.get({
			name: text,
			limit: 5,
		})
			.then((result) => {
				const options = result.data.map((item) => {
					return {
						id: item.objectId,
						title: item.name,
					};
				});
				setResidenceGroupOptions(options);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
			});
	};

	return (
		<Modal
			type="form"
			isVisible={props.isVisible}
			contentStyle={{ height: "inherit" }}
			title={"Información"}
			onClose={props.onClose}
			buttonProps={{
				onClick: onSave,
				loading: loading,
			}}
			error={error}
			options={[
				...(props.residence.province?.trim()
					? []
					: [
							{
								id: "alert",
								Data: (
									<>
										<Label
											style={{
												backgroundColor:
													ColorV2.surface.redMedium,
												fontWeight: "bold",
												marginBottom: 8,
											}}
											text="Dirección desactualizada, por favor complétala."
										/>
										<OutdatedAddressContainer>
											<Text
												type="p"
												weight="regular"
												style={{ margin: 0 }}
											>
												{props.residence.address}
											</Text>
											<Button
												onClick={() =>
													navigator.clipboard.writeText(
														props.residence.address
													)
												}
												style={{
													backgroundColor:
														ColorV2.border.invert,
													minWidth: 36,
													height: 36,
													cursor: "pointer",
													padding: 0,
												}}
											>
												{props.residence.address ? (
													<Copy
														size={23}
														color={
															ColorV2.text
																.neutralMedium
														}
													/>
												) : (
													""
												)}
											</Button>
										</OutdatedAddressContainer>
									</>
								),
							},
					  ]),
				{
					id: "name",
					title: "Nombre",
					Data: (
						<Input
							type="text"
							placeholder="Nombre"
							defaultValue={props.residence.name}
							onChange={onNameChange}
						/>
					),
				},
				{
					id: "address",
					title: "Dirección",
					Data: (
						<Form
							type="location"
							design="secondary"
							defaultLocation={
								props.residence.province
									? {
											address: props.residence.address,
											sortAddress:
												props.residence.shortAddress,
											route: props.residence.route,
											routeNumber:
												props.residence.routeNumber,
											routeInfo:
												props.residence.routeInfo,
											city: props.residence.city,
											province: props.residence.province,
											zipCode:
												props.residence.zipCode,
											country: props.residence.country,
											location: {
												lat: props.residence.location
													?.latitude,
												lng: props.residence.location
													?.longitude,
											},
											timeZone: props.residence.timeZone,
									  }
									: undefined
							}
							googleAPIKey="AIzaSyDt-MpIS9a8SsQ-e1v6I6KrmnOLK4SA-f8"
							onSubmit={onLocationChangeForm}
						/>
					),
				},
				{
					id: "phone",
					title: "Teléfono",
					Data: (
						<Input
							type="tel"
							placeholder="Teléfono"
							defaultValue={phone.value}
							country={phone.countryCode}
							countryOptions={Constant.countries()}
							onPhoneChange={onPhoneChange}
						/>
					),
				},
				{
					id: "residenceGroup",
					title: "Grupo",
					Data: (
						<Dropdown
							id={"residence-group-dropdown"}
							placeholder={"Grupo residencial"}
							type="single"
							options={residenceGroupOptions}
							selectedOptions={
								residenceGroupId
									? [residenceGroupId]
									: undefined
							}
							onSearchChange={onSearch}
							onChange={(array: Array<any>) =>
								setResidenceGroupId(array[0])
							}
						/>
					),
				},
			]}
		/>
	);
};
export default ResidenceInfoModal;
export interface Props {
	isVisible: boolean;
	residence: ResidenceScheme;
	residenceGroup?: ResidenceGroupScheme;
	onClose: () => void;
	onSave: (residence: ResidenceScheme) => void;
}
